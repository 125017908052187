module Index


open Elmish
open Fable.Remoting.Client
open Shared
open System
open Browser
open Browser.Dom

open Feliz
open Feliz.Bulma


open Fable.Core
open Fable.Core.JsInterop


type BulmaCollapsible =
    abstract destroy: unit -> unit
    abstract collapsed: unit -> bool
    abstract expand: unit -> unit
    abstract collapse: unit -> unit
    abstract close: unit -> unit
    abstract onTriggerClick: unit -> unit


type BulmaCollapsibleStatic =
    [<Emit("new $0({ element: $1, options : $2 })")>]
    abstract Create: string * obj -> BulmaCollapsible
    abstract attach: string -> BulmaCollapsible

[<ImportDefault("@creativebulma/bulma-collapsible")>]
let bulmaCollapsible:BulmaCollapsibleStatic = jsNative

type FilterParams = {
    Brand: string list;
    Capacity: string list;
    Model: string;
}

type BrandFilter = string * bool
type CapacityFilter = string * bool

type Column = Brand | Model | Capacity | DriveCount | AverageAgeMonth | DriveDays | Failures | AnnualFailureRate
type Direction = Ascending | Descending

type DataColumnDirection = {
    Column: Column;
    Direction: Direction;
}

type DataColumnDirections  = {
    Brand: Direction option;
    Model: Direction option;
    Capacity: Direction option;
    DriveCount: Direction option;
    AverageAgeMonth: Direction option;
    DriveDays: Direction option;
    Failures: Direction option;
    AnnualFailureRate: Direction option;
}

type Model = {
    BackBlazeDrives: DriveDataSql list;
    FilteredBackBlazeDrives: DriveDataSql list;
    FilterParams: FilterParams;
    DriveDataSort: DataColumnDirections;
    }

type Msg =
    | GotBackBlaze of DriveDataSql list
    | BrandFilterCheck of BrandFilter
    | CapacityFilterCheck of CapacityFilter
    | FilterParamsModelChange of string
    | FilterBackblaze
    | ToggleSort of Column
    | SortBackblaze


let backblazeApi =
    Remoting.createApi ()
    |> Remoting.withRouteBuilder Route.builder
    |> Remoting.buildProxy<IBackBlazeApi>

let initializeDataColumnDirections: DataColumnDirections =
    {
        Brand = Option<Direction>.None;
        Model = Option<Direction>.None;
        Capacity = Option<Direction>.None;
        DriveCount = Option<Direction>.None;
        AverageAgeMonth = Option<Direction>.None;
        DriveDays = Option<Direction>.None;
        Failures = Option<Direction>.None;
        AnnualFailureRate = Some(Ascending);
    }

let resetDataColumnDirections: DataColumnDirections =
    {
        Brand = Option<Direction>.None;
        Model = Option<Direction>.None;
        Capacity = Option<Direction>.None;
        DriveCount = Option<Direction>.None;
        AverageAgeMonth = Option<Direction>.None;
        DriveDays = Option<Direction>.None;
        Failures = Option<Direction>.None;
        AnnualFailureRate = Option<Direction>.None;
    }

let toggleSort(direction:Option<Direction>) =
    match direction with
    | None ->
        Some(Descending)
    | Some d ->
        match d with
        | Ascending ->
            Some(Descending)
        | Descending ->
            Some(Ascending)

let toggleSortFor(dataColumns:DataColumnDirections, column: Column) =
     let reset = resetDataColumnDirections;

     match column with
        | Brand ->
            {reset with Brand = toggleSort(dataColumns.Brand)}
        | Model ->
            {reset with Model = toggleSort(dataColumns.Model)}
        | Capacity ->
            {reset with Capacity = toggleSort(dataColumns.Capacity)}
        | DriveCount ->
            {reset with DriveCount = toggleSort(dataColumns.DriveCount)}
        | AverageAgeMonth ->
            {reset with AverageAgeMonth = toggleSort(dataColumns.AverageAgeMonth)}
        | DriveDays ->
            {reset with DriveDays = toggleSort(dataColumns.DriveDays)}
        | Failures ->
            {reset with Failures = toggleSort(dataColumns.Failures)}
        | AnnualFailureRate ->
            {reset with AnnualFailureRate = toggleSort(dataColumns.AnnualFailureRate)}


let init () : Model * Cmd<Msg> =
    let model = {
                  BackBlazeDrives = [];
                  FilteredBackBlazeDrives = [];
                  FilterParams = {Brand = []; Capacity = []; Model = ""; }
                  DriveDataSort = initializeDataColumnDirections;
                }
    let cmd =
        Cmd.OfAsync.perform backblazeApi.getDriveData () GotBackBlaze

    model, cmd

let update (msg: Msg) (model: Model) : Model * Cmd<Msg> =
    match msg with
    | GotBackBlaze drives ->
        let filterCollapse = bulmaCollapsible.attach("#filter-card")
        { model with BackBlazeDrives = drives; FilteredBackBlazeDrives = drives}, Cmd.none
    | BrandFilterCheck brandFilter ->
        if snd(brandFilter) then
            let brandsToFilter = model.FilterParams.Brand @ [fst(brandFilter)]
            {model with FilterParams = {Brand = brandsToFilter; Capacity = model.FilterParams.Capacity; Model = model.FilterParams.Model}}, Cmd.ofMsg(FilterBackblaze)
        else
            let brandsToFilter = model.FilterParams.Brand |> List.except([fst(brandFilter)])
            {model with FilterParams = {Brand = brandsToFilter; Capacity = model.FilterParams.Capacity; Model = model.FilterParams.Model}}, Cmd.ofMsg(FilterBackblaze)
    | CapacityFilterCheck capacityFilter ->
        if snd(capacityFilter) then
            let capacitiesToFilter = model.FilterParams.Capacity @ [fst(capacityFilter)]
            {model with FilterParams = {Brand = model.FilterParams.Brand; Capacity = capacitiesToFilter; Model = model.FilterParams.Model}}, Cmd.ofMsg(FilterBackblaze)
        else
            let capacitiesToFilter = model.FilterParams.Capacity |> List.except([fst(capacityFilter)])
            {model with FilterParams = {Brand = model.FilterParams.Brand; Capacity = capacitiesToFilter; Model = model.FilterParams.Model}}, Cmd.ofMsg(FilterBackblaze)
    | FilterParamsModelChange modelInput  ->
        {model with FilterParams = {Brand = model.FilterParams.Brand; Capacity = model.FilterParams.Capacity; Model = modelInput}}, Cmd.ofMsg(FilterBackblaze)
    | FilterBackblaze ->
        let mutable filteredDrives = model.BackBlazeDrives

        if model.FilterParams.Brand.Length > 0 then
            filteredDrives <- filteredDrives |> List.filter(fun x -> (model.FilterParams.Brand |> List.contains(x.Brand)))

        if model.FilterParams.Capacity.Length > 0 then
            filteredDrives <- filteredDrives |> List.filter(fun x -> (model.FilterParams.Capacity |> List.contains(x.Capacity)))

        let filtered = (filteredDrives  |> List.filter(fun x -> x.Model.Contains(model.FilterParams.Model.ToUpperInvariant())))
        {model with FilteredBackBlazeDrives = filtered}, Cmd.none
    | ToggleSort column ->
        match column with
        | Brand ->
            {model with DriveDataSort = toggleSortFor(model.DriveDataSort, Brand)}, Cmd.ofMsg(SortBackblaze)
        | Model ->
            {model with DriveDataSort = toggleSortFor(model.DriveDataSort, Model)}, Cmd.ofMsg(SortBackblaze)
        | Capacity ->
            {model with DriveDataSort = toggleSortFor(model.DriveDataSort, Capacity)}, Cmd.ofMsg(SortBackblaze)
        | DriveCount ->
            {model with DriveDataSort = toggleSortFor(model.DriveDataSort, DriveCount)}, Cmd.ofMsg(SortBackblaze)
        | AverageAgeMonth ->
            {model with DriveDataSort = toggleSortFor(model.DriveDataSort, AverageAgeMonth)}, Cmd.ofMsg(SortBackblaze)
        | DriveDays ->
            {model with DriveDataSort = toggleSortFor(model.DriveDataSort, DriveDays)}, Cmd.ofMsg(SortBackblaze)
        | Failures ->
            {model with DriveDataSort = toggleSortFor(model.DriveDataSort, Failures)}, Cmd.ofMsg(SortBackblaze)
        | AnnualFailureRate ->
            {model with DriveDataSort = toggleSortFor(model.DriveDataSort, AnnualFailureRate)}, Cmd.ofMsg(SortBackblaze)
    | SortBackblaze ->
        let mutable sortedFilteredDrives = model.FilteredBackBlazeDrives

        if model.DriveDataSort.Brand.IsSome then
            if model.DriveDataSort.Brand.Value = Descending then
                sortedFilteredDrives <- sortedFilteredDrives |> List.sortByDescending( fun x -> x.Brand)
            elif model.DriveDataSort.Brand.Value = Ascending then
                sortedFilteredDrives <- sortedFilteredDrives |> List.sortBy( fun x -> x.Brand)

        if model.DriveDataSort.Model.IsSome then
            if model.DriveDataSort.Model.Value = Descending then
                sortedFilteredDrives <- sortedFilteredDrives |> List.sortByDescending(fun x -> x.Model)
            elif model.DriveDataSort.Model.Value = Ascending then
                sortedFilteredDrives <- sortedFilteredDrives |> List.sortBy(fun x -> x.Model)

        if model.DriveDataSort.Capacity.IsSome then
            if model.DriveDataSort.Capacity.Value = Descending then
                sortedFilteredDrives <- sortedFilteredDrives |> List.sortByDescending(fun x -> x.CapacityBytes)
            elif model.DriveDataSort.Capacity.Value = Ascending then
                sortedFilteredDrives <- sortedFilteredDrives |> List.sortBy(fun x -> x.CapacityBytes)

        if model.DriveDataSort.DriveCount.IsSome then
            if model.DriveDataSort.DriveCount.Value = Descending then
                sortedFilteredDrives <- sortedFilteredDrives |> List.sortByDescending( fun x -> x.DriveCount)
            elif model.DriveDataSort.DriveCount.Value = Ascending then
                sortedFilteredDrives <- sortedFilteredDrives |> List.sortBy( fun x -> x.DriveCount)

        if model.DriveDataSort.AverageAgeMonth.IsSome then
            if model.DriveDataSort.AverageAgeMonth.Value = Descending then
                sortedFilteredDrives <- sortedFilteredDrives |> List.sortByDescending( fun x -> x.AverageAgeMonth)
            elif model.DriveDataSort.AverageAgeMonth.Value = Ascending then
                sortedFilteredDrives <- sortedFilteredDrives |> List.sortBy( fun x -> x.AverageAgeMonth)

        if model.DriveDataSort.DriveDays.IsSome then
            if model.DriveDataSort.DriveDays.Value = Descending then
                sortedFilteredDrives <- sortedFilteredDrives |> List.sortByDescending( fun x -> x.DriveDays)
            elif model.DriveDataSort.DriveDays.Value = Ascending then
                sortedFilteredDrives <- sortedFilteredDrives |> List.sortBy( fun x -> x.DriveDays)

        if model.DriveDataSort.Failures.IsSome then
            if model.DriveDataSort.Failures.Value = Descending then
                sortedFilteredDrives <- sortedFilteredDrives |> List.sortByDescending( fun x -> x.Failures)
            elif model.DriveDataSort.Failures.Value = Ascending then
                sortedFilteredDrives <- sortedFilteredDrives |> List.sortBy( fun x -> x.Failures)

        if model.DriveDataSort.AnnualFailureRate.IsSome then
            if model.DriveDataSort.AnnualFailureRate.Value = Descending then
                sortedFilteredDrives <- sortedFilteredDrives |> List.sortByDescending( fun x -> x.AnnualFailureRate)
            elif model.DriveDataSort.AnnualFailureRate.Value = Ascending then
                sortedFilteredDrives <- sortedFilteredDrives |> List.sortBy( fun x -> x.AnnualFailureRate)

        {model with FilteredBackBlazeDrives = sortedFilteredDrives}, Cmd.none


let navBrand =
    Bulma.navbarBrand.div [
        Bulma.navbarItem.a [
            prop.href "/"
            navbarItem.isActive
            prop.children [
                Html.img [
                    prop.src "/favicon.png"
                    prop.alt "Logo"
                ]
            ]
        ]
    ]

let backblazeTable (model:Model) (dispatch: Msg -> unit) =
    Bulma.table [
        table.isFullWidth
        table.isStriped
        prop.children [
            Html.thead [
                Html.tr [
                    Html.th [
                        prop.children [
                            Html.text "Brand"
                            Html.i [
                                prop.id "brand-sort"
                                prop.classes [
                                    if model.DriveDataSort.Brand.IsNone then
                                        "fa fa-fw fa-sort"
                                    elif model.DriveDataSort.Brand.Value = Ascending then
                                        "fa fa-fw fa-sort-up"
                                    elif model.DriveDataSort.Brand.Value = Descending then
                                        "fa fa-fw fa-sort-down"
                                ]
                                prop.onClick (fun _ -> dispatch (ToggleSort Brand) )
                            ]
                        ]
                     ]
                    Html.th [
                        prop.children [
                            Html.text "Model"
                            Html.i [
                                prop.id "model-sort"
                                prop.classes [
                                    if model.DriveDataSort.Model.IsNone then
                                        "fa fa-fw fa-sort"
                                    elif model.DriveDataSort.Model.Value = Ascending then
                                        "fa fa-fw fa-sort-up"
                                    elif model.DriveDataSort.Model.Value = Descending then
                                        "fa fa-fw fa-sort-down"
                                ]
                                prop.onClick (fun _ -> dispatch (ToggleSort Model) )
                            ]
                        ]
                     ]
                    Html.th [
                        prop.children [
                            Html.text "Capacity"
                            Html.i [
                                prop.id "capacity-sort"
                                prop.classes [
                                    if model.DriveDataSort.Capacity.IsNone then
                                        "fa fa-fw fa-sort"
                                    elif model.DriveDataSort.Capacity.Value = Ascending then
                                        "fa fa-fw fa-sort-up"
                                    elif model.DriveDataSort.Capacity.Value = Descending then
                                        "fa fa-fw fa-sort-down"
                                ]
                                prop.onClick (fun _ -> dispatch (ToggleSort Capacity) )
                            ]
                        ]
                     ]
                    Html.th [
                        prop.children [
                            Html.abbr [
                                prop.text "Drive Count"
                                prop.title "Number of drives reported for this model."
                            ]
                            Html.i [
                                prop.id "dive-count-sort"
                                prop.classes [
                                    if model.DriveDataSort.DriveCount.IsNone then
                                        "fa fa-fw fa-sort"
                                    elif model.DriveDataSort.DriveCount.Value = Ascending then
                                        "fa fa-fw fa-sort-up"
                                    elif model.DriveDataSort.DriveCount.Value = Descending then
                                        "fa fa-fw fa-sort-down"
                                ]
                                prop.onClick (fun _ -> dispatch (ToggleSort DriveCount) )
                            ]
                        ]
                    ]
                    Html.th [
                        prop.children [
                            Html.text "Avg. Age (months)"
                            Html.i [
                                prop.id "average-age-sort"
                                prop.classes [
                                    if model.DriveDataSort.AverageAgeMonth.IsNone then
                                        "fa fa-fw fa-sort"
                                    elif model.DriveDataSort.AverageAgeMonth.Value = Ascending then
                                        "fa fa-fw fa-sort-up"
                                    elif model.DriveDataSort.AverageAgeMonth.Value = Descending then
                                        "fa fa-fw fa-sort-down"
                                ]
                                prop.onClick (fun _ -> dispatch (ToggleSort AverageAgeMonth) )
                            ]
                        ]
                     ]
                    Html.th [
                        prop.children [
                            Html.abbr [
                                prop.text "Drive Days"
                                prop.title "Number of days the model was in use for. This includes multiple drives of the same model."
                            ]
                            Html.i [
                                prop.id "drive-days-sort"
                                prop.classes [
                                    if model.DriveDataSort.DriveDays.IsNone then
                                        "fa fa-fw fa-sort"
                                    elif model.DriveDataSort.DriveDays.Value = Ascending then
                                        "fa fa-fw fa-sort-up"
                                    elif model.DriveDataSort.DriveDays.Value = Descending then
                                        "fa fa-fw fa-sort-down"
                                ]
                                prop.onClick (fun _ -> dispatch (ToggleSort DriveDays) )
                            ]
                        ]
                    ]
                    Html.th [
                        prop.children [
                            Html.text "Failures"
                            Html.i [
                                prop.id "failures-sort"
                                prop.classes [
                                    if model.DriveDataSort.Failures.IsNone then
                                        "fa fa-fw fa-sort"
                                    elif model.DriveDataSort.Failures.Value = Ascending then
                                        "fa fa-fw fa-sort-up"
                                    elif model.DriveDataSort.Failures.Value = Descending then
                                        "fa fa-fw fa-sort-down"
                                ]
                                prop.onClick (fun _ -> dispatch (ToggleSort Failures) )
                            ]
                        ]
                    ]
                    Html.th [
                            prop.children [
                            Html.abbr [
                                prop.text "Annual Failure Rate"
                                prop.title "Drive years = (drive_days)/365 ; Annual Failure rate = (failures/(drive years)) * 100"
                            ]
                            Html.i [
                                prop.id "afr-sort"
                                prop.classes [
                                    if model.DriveDataSort.AnnualFailureRate.IsNone then
                                        "fa fa-fw fa-sort"
                                    elif model.DriveDataSort.AnnualFailureRate.Value = Ascending then
                                        "fa fa-fw fa-sort-up"
                                    elif model.DriveDataSort.AnnualFailureRate.Value = Descending then
                                        "fa fa-fw fa-sort-down"
                                ]
                                prop.onClick (fun _ -> dispatch (ToggleSort AnnualFailureRate) )
                            ]
                        ]
                    ]
                ]
            ]
            Html.tableBody [
            for driveData in model.FilteredBackBlazeDrives do
                Html.tr[
                    Html.td[ prop.text driveData.Brand ]
                    Html.td[
                        prop.children[
                            Html.a [
                                if driveData.AmazonLink.IsSome then
                                    prop.href (driveData.AmazonLink.Value )
                                    prop.text (driveData.Model)
                            ]
                        ] ]
                    Html.td[ prop.text driveData.Capacity ]
                    Html.td[ prop.text (driveData.DriveCount.ToString()) ]
                    Html.td[ prop.text (driveData.AverageAgeMonth.ToString())]
                    Html.td[ prop.text driveData.DriveDays ]
                    Html.td[
                            if driveData.Failures.IsSome then
                                prop.text driveData.Failures.Value
                        ]
                    Html.td[ prop.text driveData.AnnualFailureRate ]
                ]
            ]
        ]
    ]

let backblazeGistSection (model:Model) (dispatch: Msg -> unit) =
    Bulma.section [
        prop.classes ["block is-fluid"]
        prop.children [
            Html.p [
                prop.text "Backblaze cloud storage service helps users and businesses to save their crucial data as a backup in the cloud. With Backblaze, you can safely store everything from one computer to its external drives.
            Since 2013, Backblaze has published statistics and insights based on the hard drives in their data center."
            ]
            Html.br[]
            Html.p[
                prop.children [
                    Html.text "If you want to ensure you keep personal files and pictures in the event of a drive failure consider a free trial of "
                    Html.a [
                        prop.text "Backblaze's personal backup"
                        prop.href "https://www.backblaze.com/cloud-backup.html#af9x7w"
                    ]
                    Html.text "."
                ]
            ]
            Html.p[
                prop.children [
                    Html.text "For businesses, if you want to regularly backup data from all devices consider checking out "
                    Html.a [
                        prop.text "Backblaze's business backup plans"
                        prop.href "https://www.backblaze.com/business-pricing.html#af9x7w"
                    ]
                    Html.text "."
                ]
            ]
        ]
    ]

let backblazeTableContainer (model:Model) (dispatch: Msg -> unit) =
    Bulma.tableContainer [
        prop.classes ["tableFixHead"]
        prop.children [
            backblazeTable model dispatch
        ]
    ]

let footerSection (model: Model) (dispatch: Msg -> unit) =
    Bulma.section [
        prop.children [
            Bulma.footer [
                Bulma.content [
                    prop.classes ["has-text-centered"]
                    prop.children [
                        Html.p [
                            prop.children [
                                Html.a[
                                    prop.href "https://www.backblaze.com/b2/hard-drive-test-data.html"
                                    prop.text "Backblaze data is drive data from 2013 til Q4 2021. "
                                ]
                                Html.text "We'll try our best to keep the data presented on this page as up to date as possible, based on Backblaze's reporting schedule."
                            ]
                        ]
                        Html.p [
                            Html.text "Disclaimers: Backblaze links above and all product links (Amazon and hopefully more to come) are affiliate links."
                        ]
                        Html.p [
                            Html.a [
                                prop.href "https://forms.gle/Rjw9eR1Votbr8a1YA"
                                prop.text "Contact Us"
                            ]
                        ]
                    ]
                ]
            ]
        ]
    ]

let heroSection (model: Model) (dispatch: Msg -> unit) =
    Bulma.hero [
        prop.classes ["block is-small is-primary"]
        prop.children [
            Bulma.heroBody [
                Bulma.columns [
                    Bulma.column [
                        prop.classes ["is-12"]
                        prop.children [
                            Bulma.container [
                                Bulma.title [
                                    prop.text "Drives Fail"
                                ]
                                Bulma.subtitle [
                                    prop.classes ["is-5"]
                                    prop.children [
                                        Html.text "Drive failures suck and shopping for a replacement can be frustrating."
                                        Html.br []
                                        Html.text "Get peace of mind by picking your next drives based on failure rates reported by BackBlaze."
                                    ]
                                ]
                            ]
                        ]
                    ]
                ]
            ]
        ]
    ]

let filterCardSection (model: Model) (dispatch: Msg -> unit) =
    Bulma.card [
        Bulma.cardHeader [
            Bulma.cardHeaderTitle.p "Filters"
            Html.a [
                prop.href "#filter-card"
                prop.custom ("data-action", "collapse")
                prop.classes ["card-header-icon is-hidden-fullscreen"]
                prop.ariaLabel "more options"
                prop.children [
                    Bulma.cardHeaderIcon.span [
                        Html.i [
                            prop.className "fas fa-angle-down"
                            prop.ariaHidden true
                        ]
                    ]
                ]
            ]
        ]
        Html.div [
            prop.id "filter-card"
            prop.classes ["is-collapsible"]
            prop.children [
                Bulma.cardContent [
                    Bulma.menu [
                        prop.classes ["is-medium"]
                        prop.children [
                            Bulma.menuLabel[
                                Html.text "Model"
                            ]
                            Bulma.menuList [
                                    Html.listItem [
                                        Html.ul [
                                            Html.listItem[
                                                Bulma.input.text [
                                                    prop.value model.FilterParams.Model
                                                    color.isPrimary
                                                    prop.onChange (FilterParamsModelChange >> dispatch)
                                                ]
                                            ]
                                        ]
                                    ]
                            ]
                            Bulma.menuLabel [
                                Html.text "Brand"
                            ]
                            Bulma.menuList [
                                    Html.listItem [
                                        Html.ul [
                                            let brands = model.BackBlazeDrives |> List.map(fun x -> x.Brand) |> List.distinct
                                            for brand in brands do
                                                let checkid = $"{brand}Check"
                                                Html.listItem[
                                                    Bulma.field.div [
                                                        Checkradio.checkbox [
                                                            prop.id checkid
                                                            color.isPrimary
                                                            prop.onCheckedChange (fun status -> dispatch (BrandFilterCheck (brand, status)))
                                                        ]
                                                        Html.label [
                                                            prop.htmlFor checkid
                                                            prop.text $" {brand}"
                                                        ]
                                                    ]
                                                ]
                                            ]
                                        ]
                            ]
                            Bulma.menuLabel [
                                Html.text "Capacity"
                            ]
                            Bulma.menuList [
                                Html.listItem [
                                    Html.ul [
                                    let capacities = model.BackBlazeDrives |> List.sortByDescending(fun x -> x.CapacityBytes) |> List.map(fun x -> x.Capacity) |> List.distinct
                                    for capacity in capacities do
                                        let checkid = $"{capacity}Check"
                                        Html.listItem[
                                            Bulma.field.div [
                                                Checkradio.checkbox [
                                                    prop.id checkid
                                                    color.isPrimary
                                                    prop.onCheckedChange (fun status -> dispatch (CapacityFilterCheck (capacity, status)))
                                                ]
                                                Html.label [
                                                    prop.htmlFor checkid
                                                    prop.text $" {capacity}"
                                                ]
                                            ]
                                        ]
                                    ]
                                ]
                            ]
                        ]
                    ]
                ]
            ]
        ]
    ]


let contentSection (model: Model) (dispatch: Msg -> unit) =
    Bulma.section [
        Bulma.columns[
            Bulma.column [
                prop.classes ["is-2"]
                prop.children [
                    filterCardSection model dispatch
                ]
            ]
            Bulma.column [
                prop.classes ["is-10"]
                prop.children[ backblazeTableContainer model dispatch ]
            ]
        ]
    ]

let view (model: Model) (dispatch: Msg -> unit) =
    Html.div [
        heroSection model dispatch
        backblazeGistSection model dispatch
        contentSection model dispatch
        footerSection model dispatch
    ]

