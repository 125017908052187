namespace Shared

open System

type Todo = { Id: Guid; Description: string }

type DriveData = { Brand: string; Model: string; DriveDays: string; AnnualFailureRate: string }

type DriveDataSql = { Brand: string;
                      Model: string;
                      CapacityBytes: int64;
                      Capacity: string;
                      DriveCount: int64;
                      AverageAgeMonth: float32;
                      DriveDays: int;
                      Failures: int option;
                      AnnualFailureRate: string;
                      AmazonLink: string option;
                    }

module Todo =
    let isValid (description: string) =
        String.IsNullOrWhiteSpace description |> not

    let create (description: string) =
        { Id = Guid.NewGuid()
          Description = description }

module Route =
    let builder typeName methodName =
        sprintf "/api/%s/%s" typeName methodName

module Conversions =
    let bytesToGB(bytes:int64) =
        (float bytes)/1e+9 |> Math.Abs |> Math.Round |> int

    let bytesToTB(bytes:int64) =
        (float bytes)/1e+12 |> Math.Abs |> Math.Round|> int

    let bytesToCapacityString(bytes:int64) =
        if bytesToGB(bytes) < 1000 then
            sprintf "%d GB" (bytesToGB(bytes))
        else
            sprintf "%d TB" (bytesToTB(bytes))


type ITodosApi =
    { getTodos: unit -> Async<Todo list>
      addTodo: Todo -> Async<Todo> }

type IBackBlazeApi =
    { getDriveData: unit -> Async<DriveDataSql list>}